/* eslint-disable quotes */

export const ALL_PRODUCTS = {
  '670g_pump': {
    id: '1',
    label: 'cvent.technical.form.product_type.670g_pump',
  },
  '670g_cgm': { id: '2', label: 'cvent.technical.form.product_type.670g_cgm' },
  '640g_pump': {
    id: '3',
    label: 'cvent.technical.form.product_type.640g_pump',
  },
  '640g_cgm': { id: '4', label: 'cvent.technical.form.product_type.640g_cgm' },
  veo: { id: '5', label: 'cvent.technical.form.product_type.veo' },
  cgm: { id: '6', label: 'cvent.technical.form.product_type.cgm' },
  guardian: { id: '7', label: 'cvent.technical.form.product_type.guardian' },
  '770g_pump': {
    id: '8',
    label: 'cvent.technical.form.product_type.770g_pump',
  },
  '770g_cgm': { id: '9', label: 'cvent.technical.form.product_type.770g_cgm' },

  '780g_cgm': { id: '10', label: 'cvent.technical.form.product_type.780g_cgm' },
  '780g_pump_kid': {
    id: '11',
    label: 'cvent.technical.form.product_type.780g_pump_kid',
  },
  '780g_pump': {
    id: '12',
    label: 'cvent.technical.form.product_type.780g_pump',
  },
  '780g_cgm_kid': {
    id: '13',
    label: 'cvent.technical.form.product_type.780g_cgm_kid',
  },
  '740g_pump_cgm_kid': {
    id: '14',
    label: 'cvent.technical.form.product_type.740g_pump_cgm_kid',
  },
  '740g_cgm_kid': {
    id: '16',
    label: 'cvent.technical.form.product_type.740g_cgm_kid',
  },
  guardian_kid: {
    id: '17',
    label: 'cvent.technical.form.product_type.guardian_kid',
  },
  '780g_mini': {
    id: '18',
    label: 'cvent.technical.form.product_type.780g_mini',
  },
  guardian_cgm: {
    id: '19',
    label: 'cvent.technical.form.product_type.guardian_cgm',
  },
  '780g_pump_adult': {
    id: '20',
    label: 'cvent.technical.form.product_type.780g_pump_adult',
  },
  '780g_pump_adult2': {
    id: '21',
    label: 'cvent.technical.form.product_type.780g_pump_adult2',
  },
  '780g_cgm_adult': {
    id: '22',
    label: 'cvent.technical.form.product_type.780g_cgm_adult',
  },
};

const TECHNICAL_PRODUCTS = [
  ALL_PRODUCTS['670g_pump'],
  ALL_PRODUCTS['670g_cgm'],
  ALL_PRODUCTS['640g_pump'],
  ALL_PRODUCTS['640g_cgm'],
  ALL_PRODUCTS['veo'],
];

export const TECHNICAL_BRIEFING_PRODUCTS = [
  ALL_PRODUCTS['780g_pump_adult'],
  ALL_PRODUCTS['780g_pump_adult2'],
  ALL_PRODUCTS['780g_cgm_adult'],
  ALL_PRODUCTS['guardian_cgm'],
];

export const TECHNICAL_TRAINING_FOR_KIDS_PRODUCTS = [
  ALL_PRODUCTS['780g_pump_kid'],
  ALL_PRODUCTS['780g_pump'],
  ALL_PRODUCTS['780g_cgm_kid'],
  ALL_PRODUCTS['740g_pump_cgm_kid'],
  ALL_PRODUCTS['740g_pump_kid'],
  ALL_PRODUCTS['740g_cgm_kid'],
  ALL_PRODUCTS['guardian_kid'],
];

export const SPECIAL_TECHNICAL_INSTRUCTIONS_PRODUCTS = [
  ...TECHNICAL_PRODUCTS,
  ALL_PRODUCTS['cgm'],
  ALL_PRODUCTS['guardian'],
];

export const SPECIAL_TECHNICAL_PRODUCTS_SWITZERLAND = [
  ALL_PRODUCTS['780g_pump'],
  ALL_PRODUCTS['780g_cgm'],
  ALL_PRODUCTS['640g_pump'],
  ALL_PRODUCTS['640g_cgm'],
  ALL_PRODUCTS['670g_cgm'],
  ALL_PRODUCTS['guardian'],
];

export const SPECIAL_TECHNICAL_PRODUCTS_NETHERLANDS = [
  ALL_PRODUCTS['780g_pump'],
  ALL_PRODUCTS['780g_cgm'],
  ALL_PRODUCTS['640g_pump'],
  ALL_PRODUCTS['640g_cgm'],
  ALL_PRODUCTS['670g_cgm'],
  ALL_PRODUCTS['guardian'],
];

export const SPECIAL_TECHNICAL_PRODUCTS_GERMANY = [
  ALL_PRODUCTS['780g_pump_kid'],
  ALL_PRODUCTS['780g_pump'],
  ALL_PRODUCTS['780g_cgm_kid'],
  ALL_PRODUCTS['740g_pump_cgm_kid'],
  ALL_PRODUCTS['640g_pump'],
  ALL_PRODUCTS['740g_cgm_kid'],
  ALL_PRODUCTS['guardian_kid'],
];

export const NOT_SUPPORTED = {
  id: '1',
  label: 'cvent.techincal.form.product_type.not_supported',
};

export const SPECIAL_TECHNICAL_INSTRUCTIONS_COUNTRIES = {
  DE: SPECIAL_TECHNICAL_PRODUCTS_GERMANY,
  NL: SPECIAL_TECHNICAL_PRODUCTS_NETHERLANDS,
  CH: SPECIAL_TECHNICAL_PRODUCTS_SWITZERLAND,
};

export const PRODUCT_FAMILIARITY = [
  {
    id: '1',
    label:
      'cvent.technical.special_instructions.product_familiarity.first_insulin',
  },
  {
    id: '2',
    label:
      'cvent.technical.special_instructions.product_familiarity.already_have_another_manufacturer',
  },
  {
    id: '3',
    label:
      'cvent.technical.special_instructions.product_familiarity.already_have_from_medtronic',
  },
];

export const SHOW_FAMILIARITY_WHEN = ['1', '3'];

export const COMPANION = [
  { id: '1', label: 'cvent.technical.form.has_companion.yes' },
  { id: '2', label: 'cvent.technical.form.has_companion.no' },
];

export const CONTACT_BY = [
  { id: '1', label: 'cvent.technical.form.contact.phone' },
  { id: '2', label: 'cvent.technical.form.contact.email' },
];
export const UK_PRONOUNS = [
  {
    id: '1',
    label: 'cvent.technical.form.uk_pronouns.first_pronoun',
  },
  {
    id: '2',
    label: 'cvent.technical.form.uk_pronouns.second_pronoun',
  },
  {
    id: '3',
    label: 'cvent.technical.form.uk_pronouns.third_pronoun',
  },
];

export const DE_ONLY_PUMP_QUESTION = [
  {
    id: '1',
    label: 'cvent.technical.form.de_pump_exp.first_pump',
  },
  {
    id: '2',
    label: 'cvent.technical.form.de_pump_exp.second_pump',
  },
  {
    id: '3',
    label: 'cvent.technical.form.de_pump_exp.third_pump',
  },
];

export const DE_CGM_PUMP_QUESTION = [
  {
    id: '1',
    label: 'cvent.technical.form.de_cgm_exp.first_cgm',
  },
  {
    id: '2',
    label: 'cvent.technical.form.de_cgm_exp.second_cgm',
  },
  {
    id: '3',
    label: 'cvent.technical.form.de_cgm_exp.third_cgm',
  },
  {
    id: '4',
    label: 'cvent.technical.form.de_cgm_exp.fourth_cgm',
  },
];

export const DE_KID_CGM_QUESTION = [
  {
    id: '1',
    label: 'cvent.technical.form.de_kid_cgm_exp.first_cgm',
  },
  {
    id: '2',
    label: 'cvent.technical.form.de_kid_cgm_exp.second_cgm',
  },
  {
    id: '3',
    label: 'cvent.technical.form.de_kid_cgm_exp.third_cgm',
  },
  {
    id: '4',
    label: 'cvent.technical.form.de_kid_cgm_exp.fourth_cgm',
  },
];

export const DE_SPECIAL_CGM_QUESTION = [
  {
    id: '1',
    label: 'cvent.technical.form.de_special_cgm_exp.first_cgm',
  },
  {
    id: '2',
    label: 'cvent.technical.form.de_special_cgm_exp.second_cgm',
  },
  {
    id: '3',
    label: 'cvent.technical.form.de_special_cgm_exp.third_cgm',
  },
  {
    id: '4',
    label: 'cvent.technical.form.de_special_cgm_exp.fourth_cgm',
  },
];

export const PRODUCT_TYPE = [
  { id: '1', label: 'register.attendee.670g' },
  { id: '2', label: 'register.attendee.640g' },
  { id: '3', label: 'register.attendee.670g_system' },
];

export const ZA_THERAPY_QUESTION = [
  // {
  //   id: '1',
  //   label: 'register.attendee.guardian_connect',
  // },
  {
    id: '2',
    label: 'register.attendee.guardian_system_only',
  },
  {
    id: '3',
    label: 'register.attendee.guardian_inpen',
  },
  {
    id: '4',
    label: 'register.attendee.inpen_only',
  },
  {
    id: '5',
    label: 'register.attendee.780G',
  },
];

export const ZA_SELECTED_EXPERIENCE = 'MiniMed™ 780G';

export const ZA_EXPERIENCE_QUESTION = [
  {
    id: '1',
    label: 'register.attendee.minimed_experience',
  },
  {
    id: '2',
    label: 'register.attendee.other_no_experience',
  },
];

export const NL_HOSPITAL = [
  {
    id: '1',
    label: 'Amsterdam Umc locatie Vu kinderpoli',
  },
  {
    id: '2',
    label: 'Bernhoven Ziekenhuis',
  },
  {
    id: '3',
    label: 'Canisius Wilhelmina Ziekenhuis',
  },
  {
    id: '4',
    label: 'Diabeter Groningen',
  },
  {
    id: '5',
    label: 'Diabeter Rotterdam',
  },
  {
    id: '6',
    label: 'Diaboss',
  },
  {
    id: '7',
    label: 'Flevo Ziekenhuis',
  },
  {
    id: '8',
    label: 'Isala Klinieken Zwolle',
  },
  {
    id: '9',
    label: 'Juliana Kinderziekenhuis',
  },
  {
    id: '10',
    label: 'Kinder Diabetes Centrum',
  },
  {
    id: '11',
    label: 'LangeLand Ziekenhuis volwassen',
  },
  {
    id: '12',
    label: 'Martini Ziekenhuis',
  },
  {
    id: '13',
    label: 'Maxima Medisch Centrum',
  },
  {
    id: '14',
    label: 'Meander Medisch Centrum',
  },
  {
    id: '15',
    label: 'Meander Medisch Centrum kinderpoli',
  },
  {
    id: '16',
    label: 'Medisch Centrum Leeuwarden',
  },
  {
    id: '17',
    label: 'Medisch Spectrum Twente',
  },
  {
    id: '18',
    label: 'MUMC+',
  },
  {
    id: '19',
    label: 'Nij Smellinghe Ziekenhuis',
  },
  {
    id: '20',
    label: 'Radboudumc',
  },
  {
    id: '21',
    label: 'Rijnstate Ziekenhuis',
  },
  {
    id: '22',
    label: 'Rivas Zorggroep',
  },
  {
    id: '23',
    label: 'Rivas Zorggroep kinderpoli',
  },
  {
    id: '24',
    label: 'Rivierenland',
  },
  {
    id: '25',
    label: 'Saxenburgh Groep',
  },
  {
    id: '26',
    label: 'St. Anna Zorggroep',
  },
  {
    id: '27',
    label: 'St. Antonius Ziekenhuis Utrecht Diabetes Centraal',
  },
  {
    id: '28',
    label: 'Stichting Antonius Ziekenhuis Sneek',
  },
  {
    id: '29',
    label: 'Treant Ziekenhuiszorg locatie Bethesda',
  },
  {
    id: '30',
    label: 'Treant Ziekenhuiszorg locatie Refaja',
  },
  {
    id: '31',
    label: 'Treant Ziekenhuiszorg locatie Scheper',
  },
  {
    id: '32',
    label: 'UMCU',
  },
  {
    id: '33',
    label: 'Universitair Medisch Centrum Groningen',
  },
  {
    id: '34',
    label: 'Wilhelmina Ziekenhuis',
  },
  {
    id: '35',
    label: 'Ziekenhuis Gelderse Vallei kinderpoli',
  },
  {
    id: '36',
    label: 'Ziekenhuis Tjongerschans',
  },
  {
    id: '37',
    label: 'Ziekenhuisgroep Twente Almelo',
  },
  {
    id: '38',
    label: 'Ziekenhuisgroep Twente Hengelo',
  },
  {
    id: '39',
    label: 'Zuyderland Medisch Centrum',
  },
  {
    id: '40',
    label: 'Ziekenhuis Gelderse Vallei Volwassenpoli',
  },
  {
    id: '41',
    label: 'Hagaziekenhuis',
  },
  {
    id: '42',
    label: 'Amphia Ziekenhuis Pasteurlaan Oosterhout',
  },
  {
    id: '43',
    label: 'Amphia Ziekenhuis Pasteurlaan Oosterhout kinderpoli',
  },
  {
    id: '44',
    label: 'Amphia Ziekenhuis Langendijk Breda',
  },
  {
    id: '45',
    label: 'Amphia Ziekenhuis Molengracht Breda',
  },
  {
    id: '46',
    label: 'Ikazia Ziekenhuis',
  },
  {
    id: '47',
    label: 'Ziekenhuis Gelderse Vallei',
  },
  {
    id: '48',
    label: 'LangeLand Ziekenhuis',
  },
  {
    id: '49',
    label: 'Rivas Beatrix Gorinchem',
  },
  {
    id: '50',
    label: 'Rivas Beatrix Gorinchem kinderpoli',
  },
];

export const ZA_REGIONS = [
  {
    id: '1',
    label: 'Johannesburg',
    representative: [
      {
        id: '1',
        label: 'Hestie',
        email: 'hester.j.dreyer@medtronic.com',
        educator: [
          { id: '1', label: 'Carina' },
          {
            id: '2',
            label: 'Shani',
          },
          {
            id: '3',
            label: 'Martie',
          },
        ],
      },
      {
        id: '2',
        label: 'Claire',
        email: 'claire.sheen@medtronic.com',
        educator: [
          { id: '1', label: 'Carina' },
          {
            id: '2',
            label: 'Shani',
          },
          {
            id: '3',
            label: 'Claire',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    label: 'Pretoria',
    representative: [
      {
        id: '1',
        label: 'Hestie',
        email: 'hester.j.dreyer@medtronic.com',
        educator: [{ id: '1', label: 'Carina' }],
      },
      {
        id: '2',
        label: 'Jean-Marie',
        email: 'jeanmarie.du.preez@medtronic.com',
        educator: [{ id: '1', label: 'Carina' }],
      },
    ],
  },
  {
    id: '3',
    label: 'Western Cape',
    representative: [
      {
        id: '1',
        label: 'Erika',
        email: 'erika.kotze@medtronic.com',
        educator: [{ id: '1', label: 'Gwen' }],
      },
    ],
  },
  {
    id: '4',
    label: 'KZN',
    representative: [
      {
        id: '1',
        label: 'Viloshnee',
        email: 'selvanayagie.naidoo@medtronic.com',
        educator: [
          { id: '1', label: 'Megan' },
          {
            id: '2',
            label: 'Viloshnee',
          },
          {
            id: '3',
            label: 'Kate',
          },
          {
            id: '4',
            label: 'Kristie',
          },
        ],
      },
      {
        id: '2',
        label: 'Margie',
        email: 'margie.young@medtronic.com',
        educator: [
          { id: '1', label: 'Megan' },
          {
            id: '2',
            label: 'Margie',
          },
          {
            id: '3',
            label: 'Kate',
          },
          {
            id: '4',
            label: 'Kristie',
          },
        ],
      },
    ],
  },
  {
    id: '5',
    label: 'Free State',
    representative: [
      {
        id: '1',
        label: 'Marisa',
        email: 'marisa.van.der.merwe@medtronic.com',
        educator: [{ id: '1', label: 'Marisa' }],
      },
    ],
  },
  {
    id: '6',
    label: 'Eastern Cape',
    representative: [
      {
        id: '1',
        label: 'Margie',
        email: 'margie.young@medtronic.com',
        educator: [{ id: '1', label: 'Margie' }],
      },
    ],
  },
];

export const TECHNICAL_BRIEFING_FORM_TYPE = 'TECHNICAL_BRIEFING_FORM_TYPE';
export const TECHNICAL_TRAINING_FOR_KIDS_FORM_TYPE =
  'TECHNICAL_TRAINING_FOR_KIDS_FORM_TYPE';
export const SPECIAL_TECHNICAL_INSTRUCTIONS_FORM_TYPE =
  'SPECIAL_TECHNICAL_INSTRUCTIONS_FORM_TYPE';

export const UK_HOSPITAL_LIST = [
  { value: '1', name: 'Addenbrookes Hospital' },
  {
    value: '2',
    name: 'Aintree University Hospital',
  },
  { value: '3', name: 'Airedale General Hospital' },
  { value: '4', name: 'Alder Hey Children’s NHS FT' },
  { value: '5', name: 'Alexandra Hospital' },
  { value: '6', name: 'Altnagelvin Area Hospital' },
  { value: '7', name: 'Antrim Area Hospital' },
  { value: '8', name: 'Arrowe Park Hospital' },
  { value: '9', name: "Ashford & St. Peter's Hospital" },
  { value: '10', name: 'Ashford Hospital' },
  { value: '11', name: 'Ayr Hospital' },
  { value: '12', name: 'Balfour Hospital' },
  { value: '13', name: 'Barnet General Hospital' },
  { value: '14', name: 'Barnsley District General Hospital' },
  { value: '15', name: 'Barts & The London Nhs Trust' },
  { value: '16', name: 'Basildon University Hospital' },
  { value: '17', name: 'Basingstoke & North Hampshire Hsp' },
  { value: '18', name: 'Bassetlaw District General Hospital' },
  { value: '19', name: 'Beacon Hospital' },
  { value: '20', name: 'Beaumont Hospital' },
  { value: '21', name: 'Beckenham Hospital' },
  { value: '22', name: 'Bedford Hospital' },
  { value: '23', name: 'Birmingham Childrens Hospital' },
  { value: '24', name: 'Birmingham Heartlands Hospital' },
  { value: '25', name: 'BMI Mount Alvernia Hospital (MAH)' },
  { value: '26', name: 'BMI Princess Margaret Hosp (PMH)' },
  { value: '27', name: 'Bolton Diabetes Centre' },
  { value: '28', name: 'Bon Secours Hospital Dublin ' },
  { value: '29', name: 'Bon Secours Hospital Limerick' },
  { value: '30', name: 'Borders General Hospital' },
  { value: '31', name: 'Bradford Royal Infirmary' },
  { value: '32', name: 'Brighton General Hospital' },
  { value: '33', name: 'Bristol Royal Hsp For Children' },
  { value: '34', name: 'Bristol Royal Infirmary' },
  { value: '35', name: 'Bronglais General Hospital' },
  { value: '36', name: 'Broomfield Hospital' },
  { value: '37', name: 'BUPA Cromwell Hospital' },
  { value: '38', name: 'Burnley General Hospital' },
  { value: '39', name: 'Calderdale Royal Hospital' },
  { value: '40', name: 'Campbeltown Hospital' },
  { value: '41', name: 'Central Mvaluedlesex Hospital' },
  { value: '42', name: 'Charing Cross Hospital' },
  { value: '43', name: 'Chase Farm Hospital' },
  { value: '44', name: 'Chelsea & Westminster Hospital' },
  { value: '45', name: 'Cheltenham General Hospital' },
  { value: '46', name: 'Chesterfield Royal Hospital' },
  { value: '47', name: "Children's Health Ireland Crumlin" },
  { value: '48', name: 'Childrens Hospital Temple Street' },
  { value: '49', name: 'Chorley & South Ribble District' },
  { value: '50', name: 'Churchill Hospital' },
  { value: '51', name: 'City Hospital Birmingham' },
  { value: '52', name: 'Cockermouth Hospital' },
  { value: '53', name: 'Colchester General Hospital' },
  { value: '54', name: 'Connolly Hospital' },
  { value: '55', name: 'Conquest Hospital' },
  { value: '56', name: 'Coombe Maternity Hospital' },
  { value: '57', name: 'Cork University Hospital' },
  { value: '58', name: 'Countess Of Chester' },
  { value: '59', name: 'Craigavon Area Hospital' },
  { value: '60', name: 'Crawley Hospital' },
  { value: '61', name: 'Crichton Royal Hospital' },
  { value: '62', name: 'Crosshouse Hospital' },
  { value: '63', name: 'Croydon University Hospital' },
  { value: '64', name: 'Cumberland Infirmary' },
  { value: '65', name: 'Darent Valley Hospital' },
  { value: '66', name: 'Darlington Memorial Hospital' },
  { value: '67', name: 'Derbyshire Royal Infirmary' },
  { value: '68', name: 'Dewsbury District Hospital' },
  { value: '69', name: 'Diana Princess Of Wales Hospital' },
  { value: '70', name: 'Doncaster Royal Infirmary' },
  { value: '71', name: 'Dorset County Hospital' },
  { value: '72', name: 'Dr Grays Hospital' },
  { value: '73', name: 'Dumfries & Galloway Royal Infirmary' },
  { value: '74', name: 'Ealing Hospital' },
  { value: '75', name: 'East Surrey Hospital' },
  { value: '76', name: 'Eastbourne District General Hospital' },
  { value: '77', name: 'Edinburgh Royal Infirmary Diabetic' },
  { value: '78', name: 'Edith Cavell Hospital' },
  { value: '79', name: 'Epsom General Hospital' },
  { value: '80', name: "Evelina Children's Hospital" },
  { value: '81', name: 'Fairfield General Hospital' },
  { value: '82', name: 'Falkirk & District Royal Infirmary' },
  { value: '83', name: 'Forth Valley Royal Hospital' },
  { value: '84', name: 'Friarage Hospital' },
  { value: '85', name: 'Frimley Park Hospital' },
  { value: '86', name: 'Furness General Hospital' },
  { value: '87', name: 'Gartnavel General Hospital' },
  { value: '88', name: 'General Hospital (Jersey)' },
  { value: '89', name: 'George Eliot Hospital NHS Trust' },
  { value: '90', name: 'Gilbert Bain Hospital' },
  { value: '91', name: 'Glangwili General Hospital' },
  { value: '92', name: 'Glanrhyd Hospital' },
  { value: '93', name: 'Glasgow Royal Infirmary' },
  { value: '94', name: 'Glenfield Hospital' },
  { value: '95', name: 'Gloucestershire Royal Hospital' },
  { value: '96', name: 'Good Hope District General Hospital' },
  { value: '97', name: 'Grantham & District Hospital' },
  { value: '98', name: 'Great Ormond Street Hospital' },
  { value: '99', name: 'Great Western Hospital' },
  { value: '100', name: "Guy's and St Thomas'" },
  { value: '101', name: 'Halton General Hospital' },
  { value: '102', name: 'Hammersmith Hospital' },
  { value: '103', name: 'Harefield Hospital' },
  { value: '104', name: 'Harrogate  & District Hospital' },
  { value: '105', name: 'Hemel Hempstead General Hospital' },
  { value: '106', name: 'Hereford County Hospital' },
  { value: '107', name: 'Hermitage Medical Clinic' },
  { value: '108', name: 'Hexham General Hospital' },
  { value: '109', name: 'Hillingdon Hospital' },
  { value: '110', name: 'Hinchingbrooke Hospital' },
  { value: '111', name: 'Hinckley & District Hospital' },
  { value: '112', name: 'Homerton Hospital' },
  { value: '113', name: 'Horton General Hospital' },
  { value: '114', name: 'Hospital of St Cross' },
  { value: '115', name: 'Huddersfield Royal Infirmary' },
  { value: '116', name: 'Hull Royal Infirmary' },
  { value: '117', name: 'Imperial College London' },
  { value: '118', name: 'Inverclyde Royal Hospital' },
  { value: '119', name: 'Ipswich Hospital' },
  { value: '120', name: 'James Cook University Hospital' },
  { value: '121', name: 'James Paget University Hospital' },
  { value: '122', name: 'John Coupland Hospital' },
  { value: '123', name: 'John Radcliffe Hospital' },
  { value: '124', name: 'Kent & Sussex Weald Diabetes Centre' },
  { value: '125', name: 'Kent And Canterbury Hospital' },
  { value: '126', name: 'Kent And Sussex Hospital' },
  { value: '127', name: 'Kettering General Hospital' },
  { value: '128', name: 'Kvaluederminster General Hospital' },
  { value: '129', name: 'King Edward VII Hospital' },
  { value: '130', name: 'King George V Hospital' },
  { value: '131', name: 'Kings College Hospital' },
  { value: '132', name: 'Kings College London' },
  { value: '133', name: 'Kings Mill Hospital' },
  { value: '134', name: 'Kingsbrvaluege Private Hospitals' },
  { value: '135', name: 'Kingston Hospital' },
  { value: '136', name: 'Kirklands Hospital' },
  { value: '137', name: 'Lancashire Teaching Hospital' },
  { value: '138', name: 'Leicester General Hospital' },
  { value: '139', name: 'Leicester Royal Infirmary' },
  { value: '140', name: 'Leighton Hospital' },
  { value: '141', name: 'Letterkenny General Hospital' },
  { value: '142', name: 'Limerick Regional Hospital' },
  { value: '143', name: 'Lincoln County Hospital' },
  { value: '144', name: 'Lister Hospital Stevenage' },
  { value: '145', name: 'Liverpool Heart and Chest Hospital' },
  { value: '146', name: 'London Brvaluege Hospital' },
  { value: '147', name: 'London Clinic' },
  { value: '148', name: 'London Medical' },
  { value: '149', name: 'Longstone Hosp. (ST Lukes Hosp)' },
  { value: '150', name: 'Lorn & Islands District General' },
  { value: '151', name: 'Louth County Hospital' },
  { value: '152', name: 'Luton & Dunstable Hospital' },
  { value: '153', name: 'Macclesfield District Hospital' },
  { value: '154', name: 'Mavaluestone Hospital' },
  { value: '155', name: 'Manchester Royal Infirmary' },
  { value: '156', name: 'Manor Hospital Walsall' },
  // { value: '157', name: 'Mater Hospital' },
  { value: '158', name: 'Mater Misericordiae Hospital' },
  { value: '159', name: 'Mater Private Cork' },
  { value: '160', name: 'Mater Private Hospital Dublin' },
  { value: '161', name: 'Mayflower Community Hospital' },
  { value: '162', name: 'Mayo General Hospital' },
  { value: '163', name: 'Medway Maritime Hospital' },
  { value: '164', name: 'Mid-Ulster Hospital' },
  { value: '165', name: 'Midland Regional Hospital Portlaoise' },
  { value: '166', name: 'Midland Regional Hospital Mullingar' },
  { value: '167', name: 'Mile End Hospital - 40076309' },
  { value: '168', name: 'Milton Keynes University Hospital' },
  { value: '169', name: 'Monklands District General Hospital' },
  { value: '170', name: 'Morecambe Bay Paeds' },
  { value: '171', name: 'Morriston Hospital' },
  { value: '172', name: 'Musgrove Park Hospital' },
  { value: '173', name: 'Naas General Hospital' },
  { value: '174', name: 'National Maternity Hospital' },
  { value: '175', name: 'Neath And Port Talbot Hospital' },
  { value: '176', name: 'Nevill Hall Hospital' },
  { value: '177', name: 'New Cross Hospital (Wolverhampton)' },
  { value: '178', name: 'Newcastle General Hospital' },
  { value: '179', name: 'Newham General Hospital' },
  { value: '180', name: 'Newham University Hospital' },
  { value: '181', name: 'Ninewells Hospital & Medical School' },
  { value: '182', name: 'Nobles (Isle Of Man) Hospital' },
  { value: '183', name: 'Norfolk & Norwich' },
  { value: '184', name: 'North Devon District Hospital' },
  { value: '185', name: 'North Manchester General Hospital' },
  { value: '186', name: 'North Mvaluedlesex Hospital' },
  { value: '187', name: 'North Tees General Hospital' },
  { value: '188', name: 'North Tynesvaluee General Hospital' },
  { value: '189', name: 'Northampton General Hospital' },
  { value: '190', name: 'Northern General Hospital' },
  { value: '191', name: 'Northwick Park Hospital' },
  { value: '192', name: 'Nottingham City Hospital' },
  { value: '193', name: 'Nottingham University Hospital' },
  { value: '194', name: 'Nuffield Health Manor Hospital Oxfo' },
  { value: '195', name: 'Ormskirk District General Hospital' },
  { value: '196', name: 'Orsett Hospital' },
  { value: '197', name: 'Our Lady Of Lourdes Hospital' },
  { value: '198', name: 'Overdale Hospital' },
  { value: '199', name: 'Perth Royal Infirmary' },
  { value: '200', name: 'Peterborough City Hospital' },
  { value: '201', name: 'Pilgrim Hospital' },
  { value: '202', name: 'Pinderfields General Hospital' },
  { value: '203', name: 'Pontefract General Hospital' },
  { value: '204', name: 'Poole Hospital' },
  { value: '205', name: 'Portiuncula Hospital' },
  { value: '206', name: 'Prince Charles Hospital' },
  { value: '207', name: 'Prince Phillip Hospital' },
  { value: '208', name: 'Princess Alexandra Hospital' },
  { value: '209', name: 'Princess Elizabeth Hospital' },
  { value: '210', name: 'Princess Of Wales Hospital' },
  { value: '211', name: 'Princess Royal Hospital' },
  { value: '212', name: 'Princess Royal Hospital (Telford)' },
  { value: '213', name: 'Princess Royal University Hospital' },
  { value: '214', name: 'Queen Alexandra Hospital' },
  { value: '215', name: 'Queen Elizabeth Hospital' },
  { value: '216', name: 'Queen Elizabeth Hospital Birmingham' },
  { value: '217', name: 'Queen Elizabeth Hospital Woolwich' },
  { value: '218', name: 'Queen Elizabeth II Hospital' },
  { value: '219', name: 'Queen Elizabeth Queen Mother Hospital' },
  { value: '220', name: 'Queen Elizabeth University Hospital' },
  { value: '221', name: 'Queen Margaret Hospital Nhs Trust' },
  { value: '222', name: 'Queen Marys Hospital (Roehampton)' },
  { value: '223', name: 'Queen Marys Hospital for Children' },
  { value: '224', name: 'Queens Hospital' },
  { value: '225', name: 'Queens Hospital (Burton)' },
  { value: '226', name: 'Raigmore Hospital' },
  { value: '227', name: 'Rochdale Infirmary' },
  { value: '228', name: 'Rochford Hospital' },
  { value: '229', name: 'Rotherham General Hospital' },
  { value: '230', name: 'Rotunda Hospital' },
  { value: '231', name: 'Royal Aberdeen Childrens Hospital' },
  { value: '232', name: 'Royal Albert Edward Infirmary' },
  { value: '233', name: 'Royal Alexandra Childrens Hospital' },
  { value: '234', name: 'Royal Alexandra Hospital' },
  { value: '235', name: 'Royal Belfast Hospital for Sick' },
  { value: '236', name: 'Royal Berkshire Hospital' },
  { value: '237', name: 'Royal Blackburn Hospital' },
  { value: '238', name: 'Royal Bolton Hospital' },
  { value: '239', name: 'Royal Bournemouth Hospital' },
  { value: '240', name: 'Royal Brompton Hospital' },
  { value: '241', name: 'Royal Cornwall Hospital' },
  { value: '242', name: 'Royal Derby Hospital' },
  { value: '243', name: 'Royal Devon & Exeter Hospital' },
  { value: '244', name: 'Royal Free Hospital' },
  { value: '245', name: 'Royal Glamorgan Hospital' },
  { value: '246', name: 'Royal Gwent Hospital' },
  { value: '247', name: 'Royal Hallamshire Hospital' },
  { value: '248', name: 'Royal Hampshire County Hospital' },
  { value: '249', name: 'Royal Hospital for Children' },
  { value: '250', name: 'Royal Infirmary of Edinburgh' },
  { value: '251', name: 'Royal Lancaster Infirmary' },
  { value: '252', name: 'Royal Liverpool University Hospital' },
  { value: '253', name: 'Royal London Hospital' },
  { value: '254', name: 'Royal Manchester Childrens Hospital' },
  { value: '255', name: 'Royal Oldham Hospital' },
  { value: '256', name: 'Royal Preston Hospital' },
  { value: '257', name: 'Royal Shrewsbury Hospital' },
  { value: '258', name: 'Royal South Hampshire Hospital' },
  { value: '259', name: 'Royal Stoke University Hospital' },
  { value: '260', name: 'Royal Surrey County Hospital' },
  { value: '261', name: 'Royal Sussex County Hospital' },
  { value: '262', name: 'Royal United Hospital' },
  { value: '263', name: 'Royal Victoria Hospital' },
  { value: '264', name: 'Royal Victoria Infirmary' },
  { value: '265', name: 'Russells Hall Hospital' },
  { value: '266', name: 'Salford Royal' },
  { value: '267', name: 'Salisbury District Hospital' },
  { value: '268', name: 'Sandwell District Hospital' },
  { value: '269', name: 'Scarborough Hospital' },
  { value: '270', name: 'Scott Hospital' },
  { value: '271', name: 'Scunthorpe General Hospital' },
  { value: '272', name: "Sheffield Children's Hospital" },
  { value: '273', name: 'Singleton Hospital' },
  { value: '274', name: 'Sir Robert Peel Hospital' },
  { value: '275', name: 'Sligo General Hosptial' },
  { value: '276', name: 'Solihull Hospital' },
  { value: '277', name: 'South Devon' },
  { value: '278', name: 'South Infirmary Hospital' },
  { value: '279', name: 'South Tynesvaluee District Hospital' },
  { value: '280', name: 'South Tyrone Hospital' },
  { value: '281', name: 'South West Acute Hospital' },
  { value: '282', name: 'Southampton General Hospital' },
  { value: '283', name: 'Southend Hospital' },
  { value: '284', name: 'Southmead Hospital' },
  { value: '285', name: 'Southport District General Hospital' },
  { value: '286', name: 'Spire Manchester Hospital' },
  { value: '287', name: 'St Andrews Community Hospital' },
  { value: '288', name: 'St Bartholomews Hospital' },
  { value: '289', name: 'St Columcilles Hospital' },
  { value: '290', name: 'St Georges Hospital' },
  { value: '291', name: 'St Georges Hospital (Hornchurch)' },
  { value: '292', name: 'St Helens Hospital' },
  { value: '293', name: 'St Helier Hospital (Surrey)' },
  { value: '294', name: "St James's Hospital" },
  { value: '295', name: "St James's University Hospital" },
  { value: '296', name: 'St Johns Hospital' },
  { value: '297', name: "St Luke's Hospital-Kilkenny" },
  { value: '298', name: 'St Lukes Hospital Bradford' },
  { value: '299', name: "St Mary's Hospital" },
  { value: '300', name: 'St Marys Hospital (Newport)' },
  { value: '301', name: 'St Marys Hospital Manchester' },
  { value: '302', name: 'St Michaels Hospital (Ireland)' },
  { value: '303', name: 'St Richards Hospital' },
  { value: '304', name: 'St Vincents Private Hospital' },
  { value: '305', name: "St. Vincent's University Hospital" },
  { value: '306', name: 'Stafford General Hospital' },
  { value: '307', name: 'Stepping Hill Hospital' },
  { value: '308', name: 'Stobhill General Hospital' },
  { value: '309', name: 'Stoke Mandeville Hospital' },
  { value: '310', name: 'Sunderland Royal Hospital' },
  { value: '311', name: 'Tallaght University Hospital' },
  { value: '312', name: 'Tamesvaluee General Hospital' },
  { value: '313', name: 'The Queen Elizabeth Hospital (Gate)' },
  { value: '314', name: 'Torbay Hospital' },
  { value: '315', name: 'Trafford General Hospital' },
  { value: '316', name: 'Tunbrvaluege Wells Hospital' },
  { value: '317', name: 'Udston Hospital' },
  { value: '318', name: 'Ulster Hospital' },
  { value: '319', name: 'University College Hospital Galway' },
  { value: '320', name: 'University College Hospital London' },
  { value: '321', name: 'University Hospital BirminghamNHSFT' },
  { value: '322', name: 'University Hospital Hairmyres' },
  { value: '323', name: 'University Hospital Lewisham' },
  { value: '324', name: 'University Hospital Llandough' },
  { value: '325', name: 'University Hospital of Coventry' },
  { value: '326', name: 'University Hospital Of Hartlepool' },
  { value: '327', name: 'University Hospital Of North Durham' },
  { value: '328', name: 'University Hospital Of Wales' },
  { value: '329', name: 'University Hospital Of Wales' },
  { value: '330', name: 'University Hospital Waterford' },
  { value: '331', name: 'University Hospital Wishaw' },
  { value: '332', name: 'University Hospitals of Morecambe' },
  { value: '333', name: 'University of Cambrvaluege' },
  { value: '334', name: 'University of Chester' },
  { value: '335', name: 'University Of Wales' },
  { value: '336', name: 'Victoria Hospital Blackpool' },
  { value: '337', name: 'Victoria Hospital Kirkcaldy' },
  { value: '338', name: 'Wansbeck General Hospital' },
  { value: '339', name: 'Warrington Hospital' },
  { value: '340', name: 'Warwick Hospital' },
  { value: '341', name: 'Watford General Hospital' },
  { value: '342', name: 'West Cumberland Hospital' },
  { value: '343', name: 'West Suffolk Hospital' },
  { value: '344', name: 'West Wales General Hospital' },
  { value: '345', name: 'Western General Hospital Edinburgh' },
  { value: '346', name: 'Western Isles Hospital' },
  { value: '347', name: 'Weston General Hospital' },
  { value: '348', name: 'Wexford General Hospital' },
  { value: '349', name: 'Wexham Park Hospital' },
  { value: '350', name: 'Whipps Cross Hospital' },
  { value: '351', name: 'Whiston Hospital' },
  { value: '352', name: 'White Abbey Hospital' },
  { value: '353', name: 'Whitstable & Tankerton Hospital' },
  { value: '354', name: 'Whittington Hospital' },
  { value: '355', name: 'William Harvey Hospital' },
  { value: '356', name: 'Withybush General Hospital' },
  { value: '357', name: 'Worcestershire Royal Hospital' },
  { value: '358', name: 'Worthing Hospital' },
  { value: '359', name: 'Wrexham Maelor Hospital' },
  { value: '360', name: 'Wrightington Hospital' },
  { value: '361', name: 'Wycombe General Hospital (SHE)' },
  { value: '362', name: 'Wythenshawe Hospital' },
  { value: '363', name: 'Yardley Green Hospital' },
  { value: '364', name: 'Yeovil District Hospital' },
  { value: '365', name: 'York District Hospital' },
  { value: 'not_in_list', name: 'Other' },
];

export const ES_PROVINCE_AND_HOSPITAL = [
  {
    id: '1',
    label: 'A CORUÑA',
    hospitals: [
      { id: '1', label: 'COMPLEJO H. SANTIAGO DE COMPOSTELA' },
      {
        id: '2',
        label: 'COMPLEJO HOSPITALARIO UNIVERSITARIO A CORUÑA',
      },
      { id: '3', label: 'HOSPITAL ARQUITECTO MARCIDE - FERROL' },
      { id: '4', label: 'HOSPITAL BASICO DE LA DEFENSA FERROL' },
      { id: '5', label: 'HOSPITAL QUIRON A CORUÑA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '2',
    label: 'ALAVA',
    hospitals: [
      { id: '1', label: 'HOSPITAL SANTIAGO APOSTOL' },
      { id: '2', label: 'HOSPITAL TXAGORRITXU' },
      { id: '3', label: 'HOSPITAL VITHAS SAN JOSE' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '3',
    label: 'ALBACETE',
    hospitals: [
      { id: '1', label: 'CLINICA NUESTRA SEÑORA DEL ROSARIO' },
      { id: '2', label: 'HOSPITAL DE VILLARROBLEDO' },
      { id: '3', label: 'HOSPITAL GENERAL DE ALMANSA' },
      { id: '4', label: 'SANATORIO SANTA CRISTINA' },
      { id: '5', label: 'SESCAM - COMPLEJO HOSPITALARIO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '4',
    label: 'ALICANTE',
    hospitals: [
      { id: '1', label: 'DENIA MARINA SALUD' },
      { id: '2', label: 'HOSPITAL DE SAN JUAN' },
      { id: '3', label: 'HOSPITAL FRANCESC DE BORJA' },
      { id: '4', label: 'HOSPITAL GENERAL DE ELDA' },
      { id: '5', label: 'HOSPITAL GENERAL U. DE ALICANTE' },
      { id: '6', label: 'HOSPITAL GENERAL U. DE ELCHE' },
      { id: '7', label: 'HOSPITAL INTERNACIONAL MEDIMAR S.A.' },
      { id: '8', label: 'HOSPITAL MARINA ALTA' },
      { id: '9', label: 'HOSPITAL S.V.S. MARINA BAIXA' },
      { id: '10', label: 'HOSPITAL VEGA BAJA' },
      { id: '11', label: 'HOSPITAL VIRGEN DE LOS LIRIOS' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '5',
    label: 'ALMERIA',
    hospitals: [
      { id: '1', label: 'HOSPITAL TORRECARDENAS' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '6',
    label: 'ANDORRA',
    hospitals: [
      { id: '1', label: 'HOSPITAL NTRA. SRA. DE MERITXELL' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '7',
    label: 'ASTURIAS',
    hospitals: [
      { id: '1', label: 'HOSPITAL DE CABUENES' },
      { id: '2', label: 'HOSPITAL GENERAL DE ASTURIAS' },
      { id: '3', label: 'HOSPITAL SAN AGUSTIN' },
      { id: '4', label: 'HOSPITAL U. CENTRAL DE ASTURIAS' },
      { id: '5', label: 'HOSPITAL VALLE DEL NALON' },
      { id: '6', label: 'RAMIRO ANTUNA DE ALAIZ' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '8',
    label: 'AVILA',
    hospitals: [
      { id: '1', label: 'JCYL HOSPITAL NTRA SRA DE SONSOLES' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '9',
    label: 'BADAJOZ',
    hospitals: [
      { id: '1', label: 'GERENCIA DE AREA DE SALUD DE MERIDA' },
      { id: '2', label: 'HOSPITAL DE DON BENITO' },
      { id: '3', label: 'HOSPITAL DE ZAFRA' },
      { id: '4', label: 'HOSPITAL INFANTA CRISTINA' },
      { id: '5', label: 'HOSPITAL QUIRON SALUD CLIDEBA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '10',
    label: 'BARBASTRO',
    hospitals: [
      { id: '1', label: 'HOSPITAL DE BARBASTRO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '11',
    label: 'BARCELONA',
    hospitals: [
      { id: '1', label: 'ALTHAIA XARXA ASSISTENCIAL MANRESA' },
      { id: '2', label: 'C.S. VALLE HEBRON' },
      { id: '3', label: 'CENTRO INTERNACIONAL DE ESTUDIOS CIMA' },
      { id: '4', label: 'CENTRO MEDICO TEKNON' },
      { id: '5', label: 'CONSORCI HOSPITALARI DEL PARC TAULI' },
      { id: '6', label: 'CONSORCI SANITARI DE TERRASSA' },
      { id: '7', label: 'CONSORCI SANITARI INTEGRAL' },
      { id: '8', label: 'CONSORCI SANITARI INTEGRAL - MOISES BROGGI' },
      { id: '9', label: 'CONSORCI SANITARI INTEGRAL-H DOS DE MAIG' },
      { id: '10', label: 'CONSORCIO SANITARIO DEL MARESME' },
      { id: '11', label: 'FUNDACIO ASSISTENCIAL MUTUA DE TERRASSA' },
      { id: '12', label: 'FUNDACIÓ ROSSEND CARRASCO I FORMIGUERA' },
      { id: '13', label: 'HOSPITAL CLINIC I PROVINCIAL DE BARCELONA' },
      { id: '14', label: 'HOSPITAL COMARCAL DE SANT BERNABÉ' },
      { id: '15', label: 'HOSPITAL DE BELLVITGE' },
      { id: '16', label: 'HOSPITAL DEL MAR' },
      { id: '17', label: 'HOSPITAL GENERAL DE VIC' },
      { id: '18', label: 'HOSPITAL GENERAL GRANOLLERS' },
      { id: '19', label: 'HOSPITAL GERMANS TRIAS I PUJOL' },
      { id: '20', label: 'HOSPITAL SANT JOAN DE DEU' },
      { id: '21', label: 'HOSPITAL STA. CREU Y ST. PAU' },
      { id: '22', label: 'PATRONAT H. DE NENS DE BARCELONA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '12',
    label: 'BURGOS',
    hospitals: [
      { id: '1', label: 'HOSPITAL UNIVERSITARIO DE BURGOS' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '13',
    label: 'CACERES',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO DE CACERES' },
      { id: '2', label: 'HOSPITAL CIUDAD DE CORIA' },
      { id: '3', label: 'HOSPITAL Ntra. Sra DE LA MONTANA' },
      { id: '4', label: 'HOSPITAL VIRGEN DE GUADALUPE' },
      { id: '5', label: 'R.S. VIRGEN DEL PUERTO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '14',
    label: 'CADIZ',
    hospitals: [
      { id: '1', label: 'HOSPITAL JEREZ DE LA FRONTERA' },
      { id: '2', label: 'HOSPITAL PUERTA DEL MAR' },
      { id: '3', label: 'HOSPITAL UNIV. PUERTO REAL' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '15',
    label: 'CANTABRIA',
    hospitals: [
      { id: '1', label: 'CLINICA MOMPIA' },
      { id: '2', label: 'H. NACIONAL MARQUES DE VALDECILLA' },
      { id: '3', label: 'HOSPITAL COMARCAL SIERRALLANA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '16',
    label: 'CASTELLON',
    hospitals: [
      { id: '1', label: 'HOSPITAL COMARCAL VINAROZ' },
      { id: '2', label: 'HOSPITAL DE LA PLANA' },
      { id: '3', label: 'HOSPITAL GENERAL DE CASTELLON' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '17',
    label: 'CEUTA',
    hospitals: [
      { id: '1', label: 'HOSPITAL UNIVERSITARIO DE CEUTA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '18',
    label: 'CIUDAD REAL',
    hospitals: [
      { id: '1', label: 'HOSPITAL GENERAL DE CIUDAD REAL' },
      { id: '2', label: 'HOSPITAL GENERAL DE TOMELLOSO' },
      { id: '3', label: 'HOSPITAL GUTIÉRREZ ORTEGA' },
      { id: '4', label: 'HOSPITAL SANTA BARBARA' },
      { id: '5', label: 'HOSPITAL VIRGEN DE ALTAGRACIA' },
      { id: '6', label: 'SESCAM. COMPLEJO HOSPITALARIO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '19',
    label: 'CORDOBA',
    hospitals: [
      { id: '1', label: 'HOSPITAL QUIRON CORDOBA' },
      { id: '2', label: 'HOSPITAL REINA SOFIA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '20',
    label: 'CUENCA',
    hospitals: [
      { id: '1', label: 'SESCAM. HOSPITAL VIRGEN DE LA LUZ' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '21',
    label: 'GIBRALTAR',
    hospitals: [
      { id: '1', label: "SAINT BERNARD'S HOSPITAL" },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '22',
    label: 'GIRONA',
    hospitals: [
      { id: '1', label: 'HOSPITAL JOSEP TRUETA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '23',
    label: 'GRANADA',
    hospitals: [
      { id: '1', label: 'CLINICA INMACULADA CONCEPCION S.A.' },
      { id: '2', label: 'HOSP. UNIV. VIRGEN DE LAS NIEVES' },
      { id: '3', label: 'HOSPITAL U. SAN CECILIO - CAMPUS DE LA SALUD' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '24',
    label: 'GUADALAJARA',
    hospitals: [
      { id: '1', label: 'SESCAM. HOSPIT UNIV.DE GUADALAJARA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '25',
    label: 'GUIPUZCOA',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO DE DONOSTIA' },
      { id: '2', label: 'HOSPITAL DE MENDARO' },
      { id: '3', label: 'HOSPITAL DE ZUMARRAGA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '26',
    label: 'HUELVA',
    hospitals: [
      { id: '1', label: 'HOSPITAL JUAN RAMON JIMENEZ' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '27',
    label: 'HUESCA',
    hospitals: [
      { id: '1', label: 'HOSPITAL SAN JORGE' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '28',
    label: 'ILLES BALEARS',
    hospitals: [
      { id: '1', label: 'CLINICA DR BINIMELIS' },
      { id: '2', label: 'FUNDACIO HOSPITAL DE MANACOR' },
      { id: '3', label: 'FUNDACIÓN HOSPITAL SON LLÁTZER' },
      { id: '4', label: 'HOSPITAL CAN MISSES' },
      { id: '5', label: 'HOSPITAL COMARCAL DE INCA' },
      { id: '6', label: 'HOSPITAL U. SON ESPASES' },
      { id: '7', label: 'HOSPITAL VERGE DEL TORO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '29',
    label: 'JAEN',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO DE JAEN' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '30',
    label: 'LA RIOJA',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO SAN PEDRO' },
      { id: '2', label: 'FUNDACION HOSPITAL CALAHORRA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '31',
    label: 'LAS PALMAS',
    hospitals: [
      { id: '1', label: 'H. U. DE GRAN CANARIA - DR. NEGRIN' },
      { id: '2', label: 'HOSPITAL Dr. JOSÉ MOLINA OROSA' },
      { id: '3', label: 'HOSPITAL U. INSULAR DE GRAN CANARIA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '32',
    label: 'LEON',
    hospitals: [
      { id: '1', label: 'HOSPITAL DE LEON' },
      { id: '2', label: 'HOSPITAL DEL BIERZO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '33',
    label: 'LLEIDA',
    hospitals: [
      { id: '1', label: 'HOSP. DE LERIDA ARNAU DE VILANOVA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '34',
    label: 'LUGO',
    hospitals: [
      { id: '1', label: 'H. UNIVERSITARIO LUCUS AUGUSTI' },
      { id: '2', label: 'HOSPITAL COMARCAL DE MONFORTE' },
      { id: '3', label: 'HOSPITAL DA COSTA' },
      { id: '4', label: 'HOSPITAL XERAL DE VIGO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '35',
    label: 'MADRID',
    hospitals: [
      { id: '1', label: 'HOSPITAL PUERTA DEL SUR' },
      { id: '2', label: 'A.M. CLINIC S.L.' },
      { id: '3', label: 'CLINICA ATLAS' },
      { id: '4', label: 'HOSPITAL CENTRAL DE LA DEFENSA' },
      { id: '5', label: 'HOSPITAL DE SAN RAFAEL' },
      { id: '6', label: 'HOSPITAL DE SANCHINARRO' },
      { id: '7', label: 'HOSPITAL GREGORIO MARANON' },
      { id: '8', label: 'HOSPITAL INFANTIL U. DEL NINO JESUS' },
      { id: '9', label: 'HOSPITAL LA PAZ' },
      { id: '10', label: 'HOSPITAL RAMON Y CAJAL' },
      { id: '11', label: 'HOSPITAL RUBER INTERNACIONAL' },
      { id: '12', label: 'HOSPITAL U. DE LA PRINCESA' },
      { id: '13', label: 'CENTRO DE ESTUDIOS ENDOCRINOS' },
      { id: '14', label: 'CLINICA BELTRAN CARRILLO, S.L.' },
      { id: '15', label: 'CLINICA DIALIBRE' },
      { id: '16', label: 'CLINICA MONCLOA' },
      { id: '17', label: 'CLINICA SANTA ELENA' },
      { id: '18', label: 'FUNDACION JIMENEZ DIAZ U.T.E.' },
      { id: '19', label: 'HOSPITAL CLINICO SAN CARLOS' },
      { id: '20', label: 'HOSPITAL DE COLLADO VILLALBA' },
      { id: '21', label: 'HOSPITAL INFANTA CRISTINA MADRID' },
      { id: '22', label: 'HOSPITAL INFANTA ELENA' },
      { id: '23', label: 'HOSPITAL INFANTA LEONOR' },
      { id: '24', label: 'HOSPITAL INFANTA SOFIA' },
      { id: '25', label: 'HOSPITAL MONTEPRINCIPE' },
      { id: '26', label: 'HOSPITAL QUIRON MADRID' },
      { id: '27', label: 'HOSPITAL REY JUAN CARLOS' },
      { id: '28', label: 'SERVICIO MEDICO IBERMUTUA MUR' },
      { id: '29', label: 'E. P. HOSPITAL DEL TAJO S.A.' },
      { id: '30', label: 'FUNDACION HOSPITAL ALCORCON' },
      { id: '31', label: 'H. UNIVERSITARIO DE GETAFE' },
      { id: '32', label: 'HOSPITAL 12 DE OCTUBRE' },
      { id: '33', label: 'HOSPITAL DE ARGANDA' },
      { id: '34', label: 'HOSPITAL DE FUENLABRADA' },
      { id: '35', label: 'HOSPITAL DE MOSTOLES' },
      { id: '36', label: 'HOSPITAL SEVERO OCHOA' },
      { id: '37', label: 'HOSPITAL U. PRINCIPE DE ASTURIAS' },
      { id: '38', label: 'HOSPITAL U. PUERTA DE HIERRO' },
      { id: '39', label: 'TORREJON SALUD S.A.' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '36',
    label: 'MALAGA',
    hospitals: [
      { id: '1', label: 'CLINICA DEL ANGEL' },
      { id: '2', label: 'CLINICA RINCON BEJAR' },
      { id: '3', label: 'COMPLEJO HOSPITALARIO CARLOS HAYA' },
      { id: '4', label: 'HOSPITAL QUIRON DE MALAGA' },
      { id: '5', label: 'HOSPITAL UNIVERSITARIO MALAGA' },
      { id: '6', label: 'HOSPITAL XANIT' },
      { id: '7', label: 'SANATORIO PARQUE SAN ANTONIO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '37',
    label: 'MELILLA',
    hospitals: [
      { id: '1', label: 'HOSPITAL COMARCAL DE MELILLA ' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '38',
    label: 'MURCIA',
    hospitals: [
      { id: '1', label: 'CLINICA VIRGEN DE LA VEGA' },
      { id: '2', label: 'H.G.U. MORALES MESEGUER' },
      { id: '3', label: 'HOSPITAL COMARCAL DEL NOROESTE' },
      { id: '4', label: 'HOSPITAL DE LA VEGA' },
      { id: '5', label: 'HOSPITAL QUIRON MURCIA' },
      { id: '6', label: 'HOSPITAL RAFAEL MENDEZ - LORCA' },
      { id: '7', label: 'HOSPITAL SANTA LUCIA' },
      { id: '8', label: 'HOSPITAL SANTA MARIA DEL ROSELL' },
      { id: '9', label: 'HOSPITAL U. VIRGEN DE LA ARRIXACA' },
      { id: '10', label: 'HOSPITAL UNIVERSITARIO LOS ARCOS DEL MAR MENOR' },
      { id: '11', label: 'HOSPITAL VIRGEN DEL CASTILLO' },
      { id: '12', label: 'SERVICIO MURCIANO DE SALUD' },
      { id: '13', label: 'USP HOSPITAL SAN CARLOS' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '39',
    label: 'NAVARRA',
    hospitals: [
      { id: '1', label: 'CLINICA SAN MIGUEL ARCANGEL' },
      { id: '2', label: 'CLINICA UNIVERSITARIA DE NAVARRA' },
      { id: '3', label: 'HOSPITAL DE NAVARRA' },
      { id: '4', label: 'HOSPITAL REINA SOFIA' },
      { id: '5', label: 'HOSPITAL VIRGEN DEL CAMINO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '40',
    label: 'OURENSE',
    hospitals: [
      { id: '1', label: 'COMPLEXO HOSPITALARIO DE OURENSE' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '41',
    label: 'PALENCIA',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO DE PALENCIA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '42',
    label: 'PONTEVEDRA',
    hospitals: [
      { id: '1', label: 'COMPLEXO HOSPITALARIO DE PONTEVEDRA' },
      { id: '2', label: 'HOSPITAL DO MEIXOEIRO' },
      { id: '3', label: 'HOSPITAL POVISA S.A.' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '43',
    label: 'SALAMANCA',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO DE SALAMANCA' },
      { id: '2', label: 'HOSPITAL SANTISIMA TRINIDAD' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '44',
    label: 'SEGOVIA',
    hospitals: [
      { id: '1', label: 'HOSPITAL GENERAL SEGOVIA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '45',
    label: 'SEVILLA',
    hospitals: [
      { id: '1', label: 'CLINICA ARENAL' },
      { id: '2', label: 'CLINICA DE FATIMA GESTION S.L.' },
      { id: '3', label: 'HOSP. UNIVERSITARIO VIRGEN MACARENA' },
      { id: '4', label: 'HOSPITAL Nª Sª DE VALME' },
      { id: '5', label: 'HOSPITAL QUIRON SEVILLA - SAGRADO CORAZON' },
      { id: '6', label: 'HOSPITAL VIAMED SANTA ANGELA DE LA CRUZ' },
      { id: '7', label: 'HOSPITALES U. VIRGEN DEL ROCIO' },
      { id: '8', label: 'NUEVAS TECNOLOGIAS EN DIABETES' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '46',
    label: 'SORIA',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO SORIA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '47',
    label: 'TARRAGONA',
    hospitals: [
      { id: '1', label: 'H. UNIVERSITARIO SANT JOAN S.A.M' },
      { id: '2', label: 'HOSPITAL DE TARRAGONA JUAN XXIII' },
      { id: '3', label: 'HOSPITAL DE TORTOSA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '48',
    label: 'TENERIFE',
    hospitals: [
      { id: '1', label: 'HOSPITAL U. Ntra. Sª DE CANDELARIA' },
      { id: '2', label: 'HOSPITAL UNIVERSITARIO DE CANARIAS' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '49',
    label: 'TOLEDO',
    hospitals: [
      { id: '1', label: 'COMPLEJO HOSPITALARIO DE TOLEDO' },
      { id: '2', label: 'QUIRON TOLEDO' },
      { id: '3', label: 'SESCAM. HOSPITAL NTRA SRA DEL PRADO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '50',
    label: 'VALENCIA',
    hospitals: [
      { id: '1', label: 'CASA DE SALUD - HOSPITAL CATOLICO' },
      { id: '2', label: 'CLINICA QUIRON VALENCIA' },
      { id: '3', label: 'CONSORCIO HOSPITAL GENERAL DE VALENCIA' },
      { id: '4', label: "ESPECIALIZADA Y PRIMARIA L'HORTA MANISES S.A." },
      { id: '5', label: 'HOSPITAL ARNAU DE VILANOVA' },
      { id: '6', label: 'HOSPITAL CLINICO UNIV. DE VALENCIA' },
      { id: '7', label: 'HOSPITAL DE LA RIBERA' },
      { id: '8', label: 'HOSPITAL DE REQUENA' },
      { id: '9', label: 'HOSPITAL DE SAGUNTO' },
      { id: '10', label: 'HOSPITAL DE XATIVA LLUIS ALCANYIS' },
      { id: '11', label: 'HOSPITAL DR. PESET' },
      { id: '12', label: 'HOSPITAL LA FE' },
      { id: '13', label: 'HOSPITAL MALVARROSA' },
      { id: '14', label: 'HOSPITAL NISA VIRGEN DEL CONSUELO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '51',
    label: 'VALLADOLID',
    hospitals: [
      { id: '1', label: 'CONSULTA PRIVADA FLORINDA HERMOSO VALLADOLID' },
      { id: '2', label: 'HOSP.CLINICO UNIV.VALLADOLID' },
      { id: '3', label: 'HOSPITAL RIO HORTEGA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '52',
    label: 'VIZCAYA',
    hospitals: [
      { id: '1', label: 'HOSPITAL DE CRUCES' },
      { id: '2', label: 'HOSPITAL DE GALDAKAO' },
      { id: '3', label: 'SANTO HOSPITAL CIVIL DE BASURTO' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '53',
    label: 'ZAMORA',
    hospitals: [
      { id: '1', label: 'COMPLEJO ASISTENCIAL DE ZAMORA' },
      { id: '2', label: 'HOSPITAL RECOLETAS ZAMORA' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
  {
    id: '54',
    label: 'ZARAGOZA',
    hospitals: [
      { id: '1', label: 'CLINICA NUESTRA SEÑORA DEL PILAR' },
      { id: '2', label: 'CLINICA QUIRON DE ZARAGOZA S.A.' },
      { id: '3', label: 'ENDES-UPD, S.C.' },
      { id: '4', label: 'HOSPITAL ROYO VILLANOVA' },
      { id: '5', label: 'HOSPITAL U. MIGUEL SERVET' },
      { id: '6', label: 'SERVICIO ARAGONES DE SALUD' },
      {
        id: 'not_in_list',
        label: 'Mi hospital no aparece en la lista',
      },
    ],
  },
];
