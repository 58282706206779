/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';
import { SelectionFramePrimary } from './frames/SelectionFrame';
import Button from '../button/Button';
import routes from '../../helpers/routes';
import { SUPPORTED_COUNTRIES } from '../../constants/cvent';
import {
  addTags,
  lastTrainRG,
  initialTags,
  defFlowRet,
} from '../../actions/tags';

const HIGHLIGHT_MARKER = /\*\*\*(\S[^*]+\S)\*\*\*/g;

const mapDispatchToProps = (dispatch) => ({
  dispatchlastTrainrg: (last_trainrg) => dispatch(lastTrainRG(last_trainrg)),

  dispatchIntialTag: (initial_tags) => dispatch(initialTags(initial_tags)),

  dispatchDefFlowRet: (def_flow) => dispatch(defFlowRet(def_flow)),

  dispatchTags: (tags) => dispatch(addTags(tags)),
});

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    flowForm: state.flowFormReducer.flowForm,
    last_trainrg: state.tagsReducer.last_trainrg,
  };
};

export function SelectionSummary(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const goCalendar = () => {
    history.push(routes.calendar);
  };

  useEffect(() => {
    const userCountry =
      SUPPORTED_COUNTRIES[props.user.country.alpha2.toLowerCase()];
    const finalTags = require(`./flow/${userCountry}`).flow(
      props.summary,
      props.flowForm
    );
    let initialTagsReturned;
    if (userCountry === 'nl' || userCountry === 'gb' || userCountry === 'za') {
      initialTagsReturned = require(`./flow/${userCountry}`).getInitialTags(
        props.summary,
        props.flowForm
      );
    }
    if (
      (props.last_trainrg === undefined || props.last_trainrg === null) &&
      (userCountry === 'nl' || userCountry === 'gb' || userCountry === 'za')
    ) {
      props.dispatchlastTrainrg(null);
      props.dispatchIntialTag(initialTagsReturned);
      props.dispatchDefFlowRet(finalTags);
      props.dispatchTags(finalTags);
    } else if (
      (props.last_trainrg !== undefined || props.last_trainrg !== null) &&
      (userCountry === 'nl' || userCountry === 'gb' || userCountry === 'za')
    ) {
      props.dispatchTags(props.last_trainrg);
    } else {
      props.dispatchTags(finalTags);
    }
  }, [props]);

  const formValuesToHTML = () => {
    let composedString = '';
    let formValue = props.flowForm;
    Object.keys(formValue).forEach((key) => {
      if (
        formValue[key] === '' ||
        formValue[key] === null ||
        formValue[key] === undefined ||
        formValue[key] instanceof Array
      ) {
        delete formValue[key];
      }
    });

    Object.keys(formValue).map(function (key, index) {
      composedString +=
        t('cvent.steps.za.' + key) + ' - ' + formValue[key] + '</br>';
    });

    return HTMLReactParser(
      t(composedString).replace(
        HIGHLIGHT_MARKER,
        '<span class="text-green uppercase font-normal">$1</span>'
      )
    );
  };

  return (
    <SelectionFramePrimary
      className='mb-5'
      handleBack={props.handleBack}
      renderHeader={
        <h1 className='p-2 text-base font-bold whitespace-pre-wrap text-electric-blue md:p-5 lg:text-xl'>
         {HTMLReactParser(
          t('cvent.steps.cvent_summary_title').replace(
            HIGHLIGHT_MARKER,
            '<span class="font-normal">$1</span>'
          )
        )}
        </h1>
      }
      renderContent={
        <div className=''>
          {props.summary.map((item, k) => (
            <div key={k}>
              <div
                className={`text-dark-grey-body bg-teal ${
                  props.summary && k < props.summary.length - 1 ? 'mb-2' : ''
                }  `}
                style={{
                  border: '3px solid #D0D0D0',
                }}
              >
                <div className='self-center w-full pl-3 text-sm bg-atmospheric-white md:p-3'>
                  {HTMLReactParser(
                    t(item.stepName).replace(
                      HIGHLIGHT_MARKER,
                      '<span class="text-green uppercase font-normal">$1</span>'
                    )
                  )}
                </div>
                <div className='self-center w-full pl-3 text-sm bg-teal md:p-3'>
                  {item.stepName == 'cvent.steps.za.form_title'
                    ? formValuesToHTML()
                    : item.option.title
                    ? t(item.option.title)
                    : t(item.option.label)}
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      renderFooterButton={
        <Button
          className='px-4 mt-3 rounded-full text-dark-grey-body '
          onClick={goCalendar}
        >
          {t('cvent.steps.cvent_finish_button')}
        </Button>
      }
    />
  );
}

SelectionSummary.propTypes = {
  summary: PropTypes.array.isRequired,
  dispatchTags: PropTypes.func,
  dispatchlastTrainrg: PropTypes.func,
  dispatchIntialTag: PropTypes.func,
  dispatchDefFlowRet: PropTypes.func,
  last_trainrg: PropTypes.any,
  handleBack: PropTypes.func,
  flowForm: PropTypes.object,
  user: PropTypes.object,
};

SelectionSummary.defaultProps = {
  summary: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionSummary);
