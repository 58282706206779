export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: 'question1yesorno',
        title: 'cvent.cards.eight_step.title',
        label: 'cvent.cards.eight_step.button',
        image: '/workflow/at_lady_in_store.jpg',
        device: '/workflow/at_lady_in_store.jpg',
        description: 'cvent.cards.eight_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '2.1',
        title: 'cvent.cards.fourth_step.title',
        label: 'cvent.cards.fourth_step.button',
        image: '/workflow/at_smartguard.png',
        device: '/workflow/at_icon.png',
        description: 'cvent.cards.fourth_step.description',
        disabled: false,
        skipFlow: false,
        flowTags: [
          {
            key: 'Product',
            value: 'Smartguard',
          },
        ],
      },
      {
        from: '1',
        to: '9',
        title: 'cvent.cards.seventh_step.title',
        label: 'cvent.cards.seventh_step.button',
        image: '/workflow/at_pen.jpg',
        device: '/workflow/at_smartpen_icon.png',
        description: 'cvent.cards.seventh_step.description',
        disabled: false,
      },
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.first_step.title',
        label: 'cvent.cards.first_step.button',
        image: '/workflow/minimed.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.first_step.description',
        disabled: true,
      },
      {
        from: '1',
        to: '2.3',
        title: 'cvent.cards.fifth_step.title',
        label: 'cvent.cards.fifth_step.button',
        image: '/workflow/at_smartpen.png',
        device: '/workflow/at_smartpen_icon.png',
        description: 'cvent.cards.fifth_step.description',
        disabled: true,
        skipFlow: false,
        flowTags: [
          {
            key: 'Product',
            value: 'Smartpen',
          },
        ],
      },
      {
        from: '1',
        to: '2.2',
        title: 'cvent.cards.sixth_step.title',
        label: 'cvent.cards.sixth_step.button',
        image: '/workflow/at_picture.png',
        device: '/workflow/product-mg.png',
        description: 'cvent.cards.sixth_step.description',
        disabled: true,
      },

      {
        from: '1',
        to: '2',
        title: 'cvent.cards.third_step.title',
        label: 'cvent.cards.third_step.button',
        image: '/workflow/special-training.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.third_step.description',
        disabled: true,
        skipFlow: false,
        flowTags: [
          {
            key: 'Product',
            value: 'AM',
          },
        ],
      },
      {
        from: '1',
        to: '2',
        title: 'cvent.cards.second_step.title',
        label: 'cvent.cards.second_step.button',
        image: '/workflow/special-training-old.jpg',
        device: '/workflow/G4S_system.png',
        description: 'cvent.cards.second_step.description',
        disabled: true,
        skipFlow: false,
        flowTags: [
          {
            key: 'Product',
            value: 'Pump + CGM',
          },
        ],
      },
    ],
  },
  {
    stepNumber: 'question1yesorno',
    centered: true,
    stepName: 'cvent.steps.question1yesorno_title',
    stepDescription: 'cvent.steps.question1yesorno_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'question1yesorno',
        to: 'question1yes',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'question1yesorno',
        to: 'question1no',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'question1no',
    centered: true,
    stepName: 'cvent.steps.question1no',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'question1yes',
    centered: true,
    stepName: 'cvent.steps.question1yes',
    stepDescription: 'cvent.steps.question1yes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'question1yes',
        // to: 'Summary',
        flowTags: [
          {
            key: 'Product',
            value: 'Full System Simpl 1',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: 'question1yes',
        // to: 'Summary',
        flowTags: [
          {
            key: 'Product',
            value: 'Full System Simpl 2',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_product_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2',
        to: '6',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2',
        to: '4',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2.1',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_product_completed',
    stepDescription: 'cvent.steps.cvent_check_if_product_completed',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.1',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'Smartguard',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2.1',
        to: '4.1',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2.2',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_software_updated_title',
    stepDescription: 'cvent.steps.cvent_check_if_software_updated_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.2',
        // to: 'Summary',
        skipSummary: true,
        flowTags: [
          {
            key: 'Product',
            value: 'FOTA',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2.2',
        to: '5',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '2.3',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_pen_arrived_title',
    stepDescription: 'cvent.steps.cvent_check_if_pen_arrived_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '2.3',
        to: '6.1',
        ATshouldDisplayImage: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '2.3',
        to: 'qr_codes',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '4',
    centered: true,
    stepName: 'cvent.steps.cvent_registration_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '4.1',
    centered: true,
    stepName: 'cvent.steps.cvent_cannot_be_continued',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '5',
    centered: true,
    stepName: 'cvent.steps.cvent_technical_support',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '6',
    centered: true,
    stepName: 'cvent.steps.cgm_experience_title',
    stepDescription: 'cvent.steps.cgm_experience_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6',
        // to: 'Summary',
        flowTags: [
          {
            key: 'Product',
            value: 'Full System',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '6',
        // to: 'Summary',
        flowTags: [
          {
            key: 'Product',
            value: 'Full system2',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '6.1',
    centered: true,
    stepName: 'cvent.steps.inpen_experience_title',
    stepDescription: 'cvent.steps.inpen_experience_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '6.1',
        to: '7',
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '6.1',
        to: '4',
        flowTags: [
          {
            key: 'Product',
            value: 'Full system2',
          },
        ],
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: 'qr_codes',
    centered: true,
    stepName: 'cvent.steps.cvent_qr_codes',
    stepDescription: 'cvent.steps.cvent_qr_codes',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        ATDisplayQRCode: true,
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '7',
    centered: true,
    stepName: 'cvent.steps.cvent_settings_title',
    stepDescription: 'cvent.steps.cvent_settings_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '7',
        //to: '6.1',
        flowTags: [
          {
            key: 'Product',
            value: 'Smartpen',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '7',
        to: '8',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '8',
    centered: true,
    stepName: 'cvent.steps.cvent_connect_diabetes_team',
    stepDescription: 'cvent.steps.cvent_connect_diabetes_team',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: '9',
    centered: true,
    stepName: 'cvent.steps.cvent_check_if_started_title',
    stepDescription: 'cvent.steps.cvent_check_if_started_title',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: '9',
        //to: '6.1',
        flowTags: [
          {
            key: 'Product',
            value: 'Smartpen',
          },
        ],
        skipSummary: true,
        title: 'cvent.steps.cvent_check_if_product_arrived_yes',
        label: 'cvent.steps.cvent_check_if_product_arrived_yes',
      },
      {
        from: '9',
        to: '8.2',
        title: 'cvent.steps.cvent_check_if_product_arrived_no',
        label: 'cvent.steps.cvent_check_if_product_arrived_no',
      },
    ],
  },
  {
    stepNumber: '8.2',
    centered: true,
    stepName: 'cvent.steps.cvent_connect_diabetes_team2',
    stepDescription: 'cvent.steps.cvent_connect_diabetes_team2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
];
